#root {
	min-width: 1200px !important;
}

.form-div {
  margin-top: 10px;
}

.clear-form-button::after {
  background-color: var(--color-primary) !important;
}

.mini-list-item {
  padding: .5rem 1rem !important;
}
@font-face {
	font-family: "Kalam";
	font-style: normal;
	font-weight: bold;
	src: url("../assets/fonts/Kalam-Bold.ttf");
}

@font-face {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 600;
	src: url("../assets/fonts/Nunito-Bold.ttf");
}
@font-face {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	src: url("../assets/fonts/Nunito-ExtraBold.ttf");
}
@font-face {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 800;
	src: url("../assets/fonts/Nunito-SemiBold.ttf");
}

@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: url("../assets/fonts/Roboto-Bold.ttf");
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: url("../assets/fonts/Roboto-Light.ttf");
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: url("../assets/fonts/Roboto-Medium.ttf");
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: url("../assets/fonts/Roboto-Regular.ttf");
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 100;
	src: url("../assets/fonts/Roboto-Thin.ttf");
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Nunito" !important;
}

h1 .logo,
h2 .logo,
h3 .logo,
h4 .logo,
h5 .logo,
h6 .logo {
	font-family: "Kalam";
	letter-spacing: -0.025em;
}

.logo {
	font-family: "Kalam";
	letter-spacing: -0.025em;
}

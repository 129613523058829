.page-number {
  display: inline-block;
  padding: 0.5rem;
  margin: 0.2rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  cursor: pointer;
}

.center-pagination {
  margin: 0 auto;
  width: 80%;
}
tab-bar {
  border: 0 !important;
  margin-top: -1px;
}

card-title,
list-header,
title {
  font-family: "Nunito" !important;
}

.color-cultured {
  --color-base: var(--color-cultured);
  --color-base-rgb: var(--color-cultured-rgb);
  --color-contrast: var(--color-cultured-contrast);
  --color-contrast-rgb: var(--color-cultured-contrast-rgb);
  --color-shade: var(--color-cultured-shade);
  --color-tint: var(--color-cultured-tint);
}

:root {
  /** primary **/
  --color-primary: #e21251;
  --color-primary-rgb: 226, 18, 81;
  --color-primary-contrast: #ffffff;
  --color-primary-contrast-rgb: 255, 255, 255;
  --color-primary-shade: #c71047;
  --color-primary-tint: #e52a62;

  /** secondary **/
  --color-secondary: #3dc2ff;
  --color-secondary-rgb: 61, 194, 255;
  --color-secondary-contrast: #ffffff;
  --color-secondary-contrast-rgb: 255, 255, 255;
  --color-secondary-shade: #36abe0;
  --color-secondary-tint: #50c8ff;

  /** tertiary **/
  --color-tertiary: #5260ff;
  --color-tertiary-rgb: 82, 96, 255;
  --color-tertiary-contrast: #ffffff;
  --color-tertiary-contrast-rgb: 255, 255, 255;
  --color-tertiary-shade: #4854e0;
  --color-tertiary-tint: #6370ff;

  /** success **/
  --color-success: #2dd36f;
  --color-success-rgb: 45, 211, 111;
  --color-success-contrast: #ffffff;
  --color-success-contrast-rgb: 255, 255, 255;
  --color-success-shade: #28ba62;
  --color-success-tint: #42d77d;

  /** warning **/
  --color-warning: #ffc409;
  --color-warning-rgb: 255, 196, 9;
  --color-warning-contrast: #000000;
  --color-warning-contrast-rgb: 0, 0, 0;
  --color-warning-shade: #e0ac08;
  --color-warning-tint: #ffca22;

  /** danger **/
  --color-danger: #eb445a;
  --color-danger-rgb: 235, 68, 90;
  --color-danger-contrast: #ffffff;
  --color-danger-contrast-rgb: 255, 255, 255;
  --color-danger-shade: #cf3c4f;
  --color-danger-tint: #ed576b;

  /** dark **/
  --color-dark: #222428;
  --color-dark-rgb: 34, 36, 40;
  --color-dark-contrast: #ffffff;
  --color-dark-contrast-rgb: 255, 255, 255;
  --color-dark-shade: #1e2023;
  --color-dark-tint: #383a3e;

  /** medium **/
  --color-medium: #92949c;
  --color-medium-rgb: 146, 148, 156;
  --color-medium-contrast: #ffffff;
  --color-medium-contrast-rgb: 255, 255, 255;
  --color-medium-shade: #808289;
  --color-medium-tint: #9d9fa6;

  /** light **/
  --color-light: #f4f5f8;
  --color-light-rgb: 244, 245, 248;
  --color-light-contrast: #000000;
  --color-light-contrast-rgb: 0, 0, 0;
  --color-light-shade: #d7d8da;
  --color-light-tint: #f5f6f9;

  /** Cultured **/
  --color-cultured: #f5f4f6;
  --color-cultured-rgb: 245, 244, 246;
  --color-cultured-contrast: #000000;
  --color-cultured-contrast-rgb: 0, 0, 0;
  --color-cultured-shade: #d8d7d8;
  --color-cultured-tint: #f6f5f7;

  --font-family: "Roboto" !important;

  --text-color: #3c3248;
}
